import React from 'react'
import { Component } from 'react'

import './orderedList.css'

export default (props) => {
  console.log(props)
  return (
      <ol className='MDXOrderedList'>
      {props.children}
    </ol>
  );
};
