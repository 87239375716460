import React from "react"
import { Router } from "@reach/router"
import { Component } from "react"
import { Link } from "gatsby"

import MobileHome from "./mobileHome"
import MobileContents from "./mobileContents"
import Browser from "./browser"
import "./mobile.css"

export default class Mobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.files
    }
  }

  render() {
    return(<div id="mobileWrapper">
	   <div id="siteHeader">
	   <Link to="/" activeClassName="mobileHomeLink" className="mobileHomeLinkInactive">
	  <div id="mobileheaderTitle">
	  joe bergeron
	   </div>
	   </Link>
	  <hr className='MDXhr' style={{width: "80%"}}/>
	   </div>

	   <Router basepath="/">
	   <MobileContents path="/*" files={this.state.files}/>

	   <MobileHome path="/" files={this.state.files}/>
	   </Router>

	   </div>);
  }
}
