import React from "react"
import { Component } from "react"

import Browser from "./browser"
import ReactGA from 'react-ga';

export default class MobileHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.files
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname, [], 'Joe Bergeron');
  }
  render() {
    return(<div id="mobileHome">
	   <Browser visible={true} childrenVisible={true} files={this.state.files} navigateTo={true}/>
	   </div>);
  }
}
