import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair, gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import './inlineCode.css'

export default ({children}) => {
  return (
      <code className='inlineCode'>
      {children}
    </code>
  );
};
