import React from "react"
import deepmerge from "deepmerge"
import { Component } from "react"
import { fileToURL } from "../utils/utils"
import { navigate } from "@reach/router"

import "./browser.css"
import fileImg from "../../public/file.png";
import folderImg from "../../public/folder2.png";
import Window from "../components/window"

export default class Browser extends Component {
    constructor(props) {
      super(props);
	const canHide = (props.canHide === undefined) ? false : props.canHide;
	const visible = (props.visible === undefined) ? true : props.visible;

      const navigateTo = (props.navigateTo === undefined) ? false : props.navigateTo;
	this.state = {
	  files: props.files,
	  canHide: canHide,
	  visible: visible,
	  showChildren: this.makeShowChildren(props.files, props.childrenVisible || false),
	  openFiles: this.makeOpenFiles(props.files),
	  navigateTo: navigateTo
	};

	this.renderDirs = this.renderDirs.bind(this);
	this.toggleVisible = this.toggleVisible.bind(this);
	this.toggleOpenFile = this.toggleOpenFile.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
	if(nextProps.visible !== prevState.visible){
	    return { visible: nextProps.visible };
	}
	else return null;
    }

  makeShowChildren(files, childrenVisible) {
	const showChildren = {};
	Object.keys(files.folders).forEach(key => {
	    showChildren[key] = childrenVisible;
	});
	return showChildren;
    }

    makeOpenFiles(files) {
	const openFiles = {};
	Object.keys(files.files).forEach(key => {
	    openFiles[key] = false;
	});
	return openFiles;
    }

    toggleVisible(e) {
	const newState = Object.assign({}, this.state);
	const key = e.target.getAttribute('name')
	newState.showChildren[key] = !newState.showChildren[key];
	this.setState(newState);
    }

  toggleOpenFile(e, file)  {
    if (this.state.navigateTo) {
      navigate(fileToURL(file));
    } else {
      this.props.addWindow(file);
    }
  }


    renderDirs() {
	const dirs = [];

	this.state.files.files.forEach(file => {
	  dirs.push(<div className='browserRow' onClick={(e) => this.toggleOpenFile(e, file)} name={file.fullPath}>
		      <img src={fileImg} className='browserImg' name={file.fullPath}/>
		    <div className="browserRowTitle" name={file.fullPath}>{file.title}</div>
		      </div>);
	});

	Object.entries(this.state.files.folders).forEach(([key, value]) => {
	    dirs.push(
		    <div className='browserCol'>

		    <div className='browserRow' onClick={this.toggleVisible} name={key}>
		    <img src={folderImg} className='browserImg' name={key}/>
		    <div name={key}>{key}</div>
		</div>

		    <div className="browser" >
		<Browser addWindow={this.props.addWindow} canHide={true} visible={this.state.showChildren[key]} files={value} navigateTo={this.state.navigateTo}/>
		    </div>

		    </div>);
	});
	return dirs
    }

    render() {
	return (
		<div className='browserMain'>
		{this.state.visible && this.renderDirs()}
	    </div>
	);
    }
}
