import React from "react"
import { Component } from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import uniqid from 'uniqid'
import "./main.css"
import { URLToFile }from '../utils/utils'
import Browser from "./browser"
import Window from "./window"
import {isMobile} from 'react-device-detect';
import Mobile from "./mobile"

import Code from '../data-components/codeBlock'
import InlineCode from '../data-components/inlineCode'
import MDXLink from '../data-components/link'
import MDXOrderedList from '../data-components/orderedList'
import MDXhr from '../data-components/hr'
import { Helmet } from 'react-helmet'
import { Components } from './components'

import ReactGA from 'react-ga';

ReactGA.initialize('UA-79178224-4');

export default class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.files,
      windows: {},
      title: '',
      pathname: props.location.pathname
    };

    const openWindow = URLToFile(props.location.pathname, props.files);
    if (openWindow !== null) {
      openWindow.center = true;
      this.state.title = openWindow.title;
      this.addWindow(openWindow);
    }

    this.renderWindows = this.renderWindows.bind(this);
    this.addWindow = this.addWindow.bind(this);
    this.exitWindow = this.exitWindow.bind(this);
    this.useDesktop = this.useDesktop.bind(this);
    this.renderHead = this.renderHead.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
  }

  useDesktop() {
    const newState = Object.assign({}, this.state);
    newState.override = true;
    this.setState(newState);
  }

  exitWindow(fullPath) {
    const newState = Object.assign({}, this.state);
    newState.windows = Object.assign({}, this.state.windows);
    delete newState.windows[fullPath];
    this.setState(newState);
    // For each window, get its z-index. The one with the greatest z-index should be brought to front, and URL changed.
  }

  getWindowCoords(initialWidth, center=false) {
    return {
      width: Math.round(this.state.width/2 - initialWidth/2 + ((Math.random() - .5)*2)*(center ? 0 : 100)),
      height: Math.round(50 + ((Math.random() - .5)*2)*(center ? 0 : 30))
    }
  }

  componentDidMount() {
    const newState = Object.assign({}, this.state);
    newState.height = window.innerHeight;
    newState.width = window.innerWidth;
    newState.loaded = true;
    if (!isMobile) {
      ReactGA.pageview(window.location.pathname, [], this.state.title == '' ? 'Joe Bergeron' : this.state.title);
    }
    this.setState(newState)
  }

  renderWindows() {
    const windows = [];

    Object.entries(this.state.windows).forEach(([fullPath, windowInfo]) => {
      const {height, width} = this.getWindowCoords(windowInfo.initialWidth, windowInfo.center);
      windows.push(<Window title={windowInfo.title}
		   uid={uniqid()}
		   fullPath={fullPath}
		   allowExit={true}
		   exitWindow={this.exitWindow}
		   minHeight={100}
		   minWidth={300}
                   changeUrl={true}
                   urlOnClick={windowInfo.url}
                   startingX={width}
                   startingY={height}
		   initialWidth={windowInfo.initialWidth}
                   noMaxWidth={windowInfo.noMaxWidth}
		   updateTitle={this.updateTitle}>
		   <div>
                   <MDXProvider
                   components={Components}
                   >
                   <MDXRenderer>
		   {windowInfo.data.body}
                   </MDXRenderer>
                   </MDXProvider>
		   </div>
		   </Window>)
    });
    return windows;
  }

  updateTitle(title) {
    const newState = Object.assign({}, this.state);
    newState.title = title;
    this.setState(newState);
  }

  renderHead() {
    const title = this.state.title == '' ? 'Joe Bergeron' : `Joe Bergeron | ${this.state.title}`
      return (
	  <Helmet>

	  <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400&display=swap" rel="stylesheet"/>
                <meta charSet="utf-8" />
          <title>{title}</title>
            </Helmet>
      )
    }

  addWindow(windowInfo) {
    const newState = Object.assign({}, this.state);
    if (!(windowInfo.fullPath in  newState.windows)) {
      newState.windows[windowInfo.fullPath] = windowInfo;
      this.setState(newState);
    }
  }

  render() {
    if (!this.state.loaded) {
      return <div/>
    }
    if (isMobile && !this.state.override) {
      return (<div>
	      {this.renderHead()}
	      <div id='mobile'>
	      <Mobile pathname={this.state.pathname} files={this.state.files}/>
	      </div>
	      </div>);
    }
  return (<div id="fullSite">
	  {this.renderHead()}
	    <Window title="files" enableResizing={false} disableScroll={true} uid={uniqid()}>
	    <div className='main'><Browser visible={true} childrenVisible={true} files={this.state.files} addWindow={this.addWindow}/></div>
	    </Window>
          {this.state.width && this.state.height && this.renderWindows()}
	  <div id="siteHeader">
	  <div id="headerTitle">
	  joe bergeron
	  </div>
	  <hr className='MDXhr' style={{width: "80%"}}/>
	  </div>
	  <div id='background'>
	      </div>
	    </div>);
  }
}
