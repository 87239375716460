import React from "react"
import  {useRef} from "react"
import deepmerge from "deepmerge"
import { Component } from "react"
import Loadable from "@loadable/component"
import { Rnd } from "react-rnd"
import { navigate } from "@reach/router"

import ReactGA from 'react-ga';

import "./window.css"

const rndManagerRef = {
  maxZIndex: "999",
  prevDraggedNode: null,
  prevDraggedNodeZIndex: null
};

const zCounter = {
  z: 1
}
export default class Window extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: props.uid || undefined,
      maxContentWidth: props.noMaxWidth ? '' : '1000px',
      overflowX: props.disableScroll ? ' ' : 'auto',
      initialWidth: props.initialWidth || 'auto',
      disableScroll: props.disableScroll,
      title: props.title,
      visible: true,
      loaded: false,
      height: 'auto',
      width: props.initialWidth || 'auto',
      rndManagerRef,
      minHeight:  props.minHeight || 100,
      minWidth: props.minWidth || 200,
      maxWidth: 1500,
      enableResizing: props.enableResizing,
      changeUrl: props.changeUrl || false,
      urlOnClick: props.urlOnClick,
      startingX: props.startingX || 50,
      startingY: props.startingY || 50,
      updateTitle: props.updateTitle
    }

    this.onClick = this.onClick.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.updateZIndex = this.updateZIndex.bind(this);
  }

  onClick(e) {
    e.preventDefault()
    e.persist();
    const newState = Object.assign({}, this.state);
    newState.visible = !newState.visible;
    const width = e.target.parentNode.parentNode.parentNode.offsetWidth;
    this.setState(newState, () => {
      if (!newState.visible) {
	this.rnd.updateSize({height: 23, width: width});
      } else {
	this.rnd.updateSize({height: this.state.height, width: this.state.width});
      }
    });
  }

  componentDidMount() {
    const newState = Object.assign({}, this.state);
    newState.loaded = true;
    if (this.state.updateTitle) {
	  this.state.updateTitle(this.state.title);
    }
    this.setState(newState, () => {
      if (this.state.changeUrl ) {
        navigate(this.state.urlOnClick, {replace: true});
	ReactGA.pageview(this.state.urlOnClick, [], this.state.title);
      }
      document.getElementById(this.state.uid).style.zIndex=zCounter.z;
      zCounter.z = zCounter.z+1;
    });
  }

  updateZIndex(e) {
    document.getElementById(this.state.uid).style.zIndex=zCounter.z;
    zCounter.z = zCounter.z+1;
    return

    const node = e.currentTarget;
    const manager = rndManagerRef;
    if (manager.prevDraggedNode) {
      manager.prevDraggedNode.style.zIndex = manager.prevDraggedNodeZIndex;
    }
    manager.prevDraggedNode = node;
    manager.prevDraggedNodeZIndex = manager.prevDraggedNode.style.zIndex;
    manager.prevDraggedNode.style.zIndex = manager.maxZIndex;
  }

  updateHeight(e, direction, ref, delta, position) {
    if (this.state.visible) {
      const newHeight = Math.max(this.state.minHeight, parseInt(ref.style.height.slice(0,-2)));
      const newWidth = Math.min(Math.max(this.state.minWidth, parseInt(ref.style.width.slice(0,-2))), this.state.maxWidth);
      this.setState({height: newHeight, width: newWidth}, () => {
	this.rnd.updateSize({height: newHeight+23, width: newWidth});
      });
    } else {
      const newWidth = Math.min(Math.max(this.state.minWidth, parseInt(ref.style.width.slice(0,-2))), this.state.maxWidth);
      this.setState({width: newWidth}, () => {
	this.rnd.updateSize({height: 23, width: newWidth});
      });
    }
  }

  render() {
    return (<div>{ this.state.loaded &&
		   <Rnd
		   id={this.state.uid}
      ref={(c) => {this.rnd = c}}
      className='draggable'
      onResize={(e, direction, ref, delta, position) => {this.updateHeight(e, direction, ref, delta, position)}}
      default={{
	x: this.state.startingX,
	y: document.getElementById('background').getBoundingClientRect().top + this.state.startingY,
	width: this.state.initialWidth
      }}
                   bounds='#background'
                   lockAspectRatioExtraHeight={23}
      enableResizing={this.state.enableResizing}
                   maxWidth={this.state.maxWidth}
                   maxHeight={document.getElementById('background').offsetHeight - 100}
      dragHandleClassName="dragHandle"
      onMouseDown={(e) => {
        if (this.state.updateTitle) {
            this.state.updateTitle(this.state.title);
        }
        if (this.state.changeUrl) {
	  const pathname = window.location.pathname;

	  if (window.location.pathname != this.state.urlOnClick && !this.state.disableScroll) {
	    navigate(this.state.urlOnClick, {replace: true})
	    ReactGA.pageview(this.state.urlOnClick, [], this.state.title);
	  }

        }

        this.updateZIndex(e);
      }}

      onDragStart={(e, node) => {
	e.stopPropagation();
	this.updateZIndex(e);
      }}
	>
	<div className="window" style={{'height': !this.state.visible ? 0 : this.state.height}}>

	<div className='dragHandle'>
	<div className='windowTitle'>
        {this.state.title}
	</div>
	<div className='ctrlWrapper'>
	<div className='minusButton' onClick={this.onClick}> {this.state.visible ? '-' : '+' }</div>
	{this.props.allowExit &&
	 <div className='exitButton' onClick={() => {this.props.exitWindow(this.props.fullPath)}}>x</div> }
      </div>
	</div>

		   {this.state.visible &&
		    <div className='mainWrapper'>
		    <div className="windowMain" style={{'overflowX': this.state.overflowX, 'height': this.state.height, 'width': '100%', 'maxWidth': this.state.maxContentWidth, 'maxHeight': document.getElementById('background').offsetHeight - 100}}>
       {this.props.children}

		    </div>
		   </div>}
      </div>
	</Rnd>
    }</div>);
  }
}
