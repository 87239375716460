import React from 'react'

import './hr.css'

export default ({children}) => {
  return (
      <hr className='MDXhr'>
      {children}
       </hr>
  );
};
