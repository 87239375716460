import React from 'react'
import { Component } from 'react'

import './link.css'

export default (props) => {
  if (props.href.substring(0,3) === '#fn') {
  return (
      <a className='MDXLink' href={props.href}>
        {props.children}
      </a>
  );
  }
  return (
      <a className='MDXLink' href={props.href} target='_blank'>
        {props.children}
      </a>
  );
};
