import React from "react"

import Code from '../data-components/codeBlock'
import InlineCode from '../data-components/inlineCode'
import MDXLink from '../data-components/link'
import MDXOrderedList from '../data-components/orderedList'
import MDXhr from '../data-components/hr'

export const Components = {
  code: props => <Code {...props} />,
  ol: props => <MDXOrderedList {...props} />,
  a: props => <MDXLink {...props} />,
  div: props => <div {...props} />,
  script: props => <script {...props} />,
  inlineCode: props => <InlineCode {...props} />,
  hr: props => <MDXhr {...props} />
}
