import React from "react"
import { Router, navigate } from "@reach/router"
import { Component } from "react"
import { Link } from "gatsby"
import { URLToFile }from '../utils/utils'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import MobileHome from "./mobileHome"
import Browser from "./browser"
import "./mobile.css"
import { Components } from './components'
import ReactGA from 'react-ga';

export default class MobileContents extends Component {
  constructor(props) {
    super(props);

    const file = URLToFile(window.location.pathname, props.files);
    this.state = {
      file
    }
  }

  componentDidMount() {
    if (this.state.file) {
      ReactGA.pageview(window.location.pathname, [], this.state.file.title);
    }
  }
  render() {
    if (!this.state.file) {
      navigate('/');
    }

    return(<div id="mobileContents">
	   <div className='mobileMainWrapper'>
	   <div className="mobileWindowMain" style={{'overflowX': this.state.overflowX, 'height': this.state.height, 'width': '100%', 'maxWidth': this.state.maxContentWidth}}>
	   <div>
           <MDXProvider
           components={Components}
           >
           <MDXRenderer>
	   {this.state.file.data.body}
           </MDXRenderer>
           </MDXProvider>
	   </div>
	   </div>
	   </div>
	   </div>);
  }
}
