import React, { Component } from "react"
import { Router, Link, Location } from "@reach/router"
import { graphql } from "gatsby"
import deepmerge from "deepmerge"
import Main from '../components/main'
import { fileToTitle, fileToURL } from '../utils/utils'


function parseFiles(mdx, dataRoot) {
  const fileDicts = []
  mdx.forEach(edge => {
    const relativePath = edge.node.fileAbsolutePath.substr(dataRoot.length);
    const path = relativePath.split('/');
    fileDicts.push(createFileObj(path, relativePath, edge.node));
  });
  return {
    folders: {'root': deepmerge.all(fileDicts)},
    files: []
  };
}

function createFileObj(path, fullPath, data) {
  if (path.length == 1) {
    const name = path[0]
    const title = fileToTitle({data, name, fullPath})
    const url = fileToURL({data, name, title, fullPath});
    return {
      files: [{
        name,
        fullPath,
        data,
        title,
        url,
        initialWidth: data.frontmatter.initialWidth || 1000,
        noMaxWidth: data.frontmatter.noMaxWidth || false
      }],
      folders: []
    }
  }
  const dir = path[0];
  return {
    folders: {[dir]: createFileObj(path.splice(1), fullPath, data)},
    files: []
  }
}

class IndexPage extends Component {
  constructor(props) {
    super(props);
    const mdx = props.data.allMdx.edges;
    const dataRoot = props.data.site.siteMetadata.dataRoot;
    this.state = {
      files: parseFiles(mdx, dataRoot)
    }

  }

  componentDidMount() {
    const newState = Object.assign({}, this.state);
    newState.loaded = true;
    this.setState(newState);
  }

  render() {
    return (
        <div>
	{this.state.loaded &&
        <Router>
      <Main default path="/" files={this.state.files}/>
	    </Router>
	}
      </div>
    )
  }
}

export default IndexPage

export const query = graphql`
query MyQuery {
  allMdx {
    edges {
      node {
        body
        id
        frontmatter {
          title
          date
          initialWidth
          noMaxWidth
        }
        fileAbsolutePath
      }
    }
  }
  site {
    siteMetadata {
      dataRoot
    }
  }
}
`
