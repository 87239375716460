import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair, gruvboxDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './codeBlock.css'

export default ({children, className}) => {
  const language = className.replace(/language-/, '')
  return (
      <SyntaxHighlighter className='codeBlock' language={language} style={gruvboxDark}>
      {children}
    </SyntaxHighlighter>
  );
};
