import he from 'he';

export function fileToURL(file) {
  const beginning = file.fullPath.substr(0,file.fullPath.lastIndexOf('/')+1);
  return encodeURI(`/${beginning}${file.title}/`);
}

export function fileToTitle(file) {
  if (file.data.frontmatter.title) {
    return he.decode(file.data.frontmatter.title);
  }
  return he.decode(file.name);
}

export function URLToFile(url, files) {
  let result = null;
  files.files.forEach(file => {
    if (file.url === url) {
      result = file;
    }
  });

  if (result !== null) {
    return result
  }

  Object.values(files.folders).forEach(folder => {
    const tmp = URLToFile(url, folder)
    if (tmp !== null) {
      result = tmp
    }
  });

  return result;
}

